import "@/assets/css/style.scss";
import { createApp } from "vue";
import App from "./app/App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import i18n from "@/i18n";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import iconPack from "./fa-config";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs.vue";
import FabButton from "./components/fabbutton/FabButton.vue";
import TopNavigation from "./components/top-navigation/TopNavigation.vue";
import TopNavigationSettings from "@/components/top-navigation-settings/TopNavigationSettings.vue";
import TopNavigationAdministration from "@/components/top-navigation-administration/TopNavigationAdministration.vue";
import TablePager from "./components/table-pager/TablePager.vue";
import FormsFilter from "./components/forms-filter/FormsFilter.vue";
import FilterChip from "@/components/filter-chip/FilterChip.vue";
import PageSpace from "@/components/page-space/PageSpace.vue"
import QInputS from "@/components/skeleton/q-input/QInputS.vue"
import QTableS from "@/components/skeleton/q-table/QTableS.vue"
import VueExcelXlsx from "vue-excel-xlsx";
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
import VueExcelEditor from 'vue3-excel-editor'
import CountryFlag from 'vue-country-flag-next'

const DEFAULT_TITLE = "Welcome!";

iconPack()

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.state.isAuthenticated && from.fullPath) {
      next({
        path: "/home",
      });
    } else {
      next();
    }
  }
});

router.afterEach((to) => {
  document.title = `VaultN | ${to.meta.title || DEFAULT_TITLE}`;
});

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('Breadcrumbs', Breadcrumbs)
app.component('FabButton', FabButton)
app.component('TopNavigation', TopNavigation)
app.component('TopNavigationSettings', TopNavigationSettings)
app.component('TopNavigationAdministration', TopNavigationAdministration)
app.component('TablePager', TablePager)
app.component('FormsFilter', FormsFilter)
app.component('FilterChip', FilterChip)
app.component('PageSpace', PageSpace)
app.component('QInputS', QInputS)
app.component('QTableS', QTableS)

app.use(i18n)
app.use(store)
app.use(router)
app.use(Quasar, quasarUserOptions)
app.use(VueExcelXlsx)
app.use(Vue3Tour)
app.use(VueExcelEditor)
app.use(CountryFlag)

app.mount("#app");
