import store from "@/store";
import i18n from "@/i18n";

export function returnErrors(response: any) {
    let message;
    const errorStatus = response.response.status;
    if (errorStatus == 400) {
        if (response.response.data.title) {
            message = response.response.data.title;
        } else if (response.response.data.error) {
            message = response.response.data.error;
        } else if (response.response.data) {
            message = response.response.data;
        } else if (response.data.error) {
            message = response.data.error;
        } else if (response.response.data.errors) {
            for (let i = 0; i < response.response.data.errors.length; i++) {
                message = response.response.data.errors[i].error + i18n.global.t('common.atRow').toString() + response.response.data.errors[i].index;
            }
        } else if (response.response.data.message) {
            message = response.response.data.message;
        } else {
            message = response.response.data;
        }
    } else if (errorStatus == 500) {
        message = i18n.global.t("common.ErrorType500").toString();
    } else if (errorStatus == 406) {
        message = response.response.data.errorMessage;
    } else if (errorStatus == 404) {
        message = i18n.global.t("common.ErrorType404").toString();
    } else if (errorStatus == 401) {
        message = i18n.global.t("common.ErrorType401").toString();
        store.dispatch("logOut");
    } else {
        message = response.response.data;
    }
    store.state.loadingSpinner = false;
    return message;
}