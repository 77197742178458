import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3075983b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "justify-end row top-filter width-100-perc"
}
const _hoisted_2 = { class: "row items-center justify-end" }
const _hoisted_3 = {
  key: 6,
  class: "label top-minus-6"
}
const _hoisted_4 = { class: "col-auto q-mx-md" }
const _hoisted_5 = {
  key: 0,
  class: "q-px-md border-filter__left"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12 flex flex-center" }
const _hoisted_8 = { class: "col-12 flex flex-center" }
const _hoisted_9 = { class: "mid-blue" }
const _hoisted_10 = { class: "q-mt-md q-mb-none q-py-none q-ml-md" }
const _hoisted_11 = { class: "row justify-center q-my-md top-filter" }
const _hoisted_12 = { class: "row items-center justify-end" }
const _hoisted_13 = {
  key: 6,
  class: "label top-minus-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.formElements.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formElements.filter((t) => !t.isDialog), (element) => {
            return (_openBlock(), _createElementBlock("div", {
              key: element.propertyName,
              class: "col q-ml-sm"
            }, [
              _createVNode(_component_q_form, {
                onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filter())),
                class: "form-container height-100-perc width-100-perc"
              }, {
                default: _withCtx(() => [
                  (
            element.vueType == 5 &&
            element.propertyName != undefined &&
            element.isReadOnly == false &&
            !element.isHidden
          )
                    ? (_openBlock(), _createBlock(_component_q_input, {
                        key: 0,
                        rounded: "",
                        outlined: "",
                        type: "text",
                        ref_for: true,
                        ref: element.propertyName,
                        modelValue: element.value,
                        "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                        label: element.title,
                        "lazy-rules": "",
                        disable: element.isReadOnly,
                        rules: element.isMandatory ? [_ctx.rules.required] : [],
                        "data-cy": element.propertyName + '-input'
                      }, _createSlots({ _: 2 }, [
                        (
              element.propertyName === 'Filter_Search' ||
              element.propertyName === 'Filter_Product' ||
              element.propertyName === 'FilterText'
            )
                          ? {
                              name: "append",
                              fn: _withCtx(() => [
                                _createVNode(_component_q_icon, {
                                  class: "mid-blue",
                                  name: "search"
                                })
                              ]),
                              key: "0"
                            }
                          : undefined
                      ]), 1032, ["modelValue", "onUpdate:modelValue", "label", "disable", "rules", "data-cy"]))
                    : _createCommentVNode("", true),
                  (
            element.vueType == 6 &&
            element.propertyName != undefined &&
            element.isReadOnly == false &&
            !element.isHidden
          )
                    ? (_openBlock(), _createBlock(_component_q_input, {
                        key: 1,
                        rounded: "",
                        outlined: "",
                        type: "text",
                        ref_for: true,
                        ref: element.propertyName,
                        modelValue: element.value,
                        "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                        label: element.title,
                        "lazy-rules": "",
                        class: "read-only-field",
                        disable: element.isReadOnly,
                        rules: element.isMandatory ? [_ctx.rules.required] : [],
                        "data-cy": element.propertyName + '-input'
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disable", "rules", "data-cy"]))
                    : _createCommentVNode("", true),
                  (
            element.vueType == 13 &&
            element.propertyName != undefined &&
            !element.isHidden
          )
                    ? (_openBlock(), _createBlock(_component_q_input, {
                        key: 2,
                        rounded: "",
                        outlined: "",
                        ref_for: true,
                        ref: element.propertyName,
                        modelValue: element.value,
                        "onUpdate:modelValue": ($event: any) => ((element.value) = $event),
                        label: element.title,
                        disable: element.isReadOnly,
                        rules: element.isMandatory ? [_ctx.rules.required] : [],
                        "data-cy": element.propertyName + '-input'
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: "event",
                            class: "cursor-pointer"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_popup_proxy, {
                                cover: "",
                                "transition-show": "scale",
                                "transition-hide": "scale"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_date, {
                                    options: 
                    element.propertyName === 'Filter_EndDate'
                      ? (date) => date >= _ctx.endDateAutoLimit(date)
                      : (date) => date <= _ctx.startDateAutoLimit(date)
                  ,
                                    modelValue: element.value,
                                    "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                    mask: "DD/MM/YYYY"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_2, [
                                        _withDirectives(_createVNode(_component_q_btn, {
                                          label: "Close",
                                          color: "primary",
                                          flat: ""
                                        }, null, 512), [
                                          [_directive_close_popup]
                                        ])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["options", "modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "label", "disable", "rules", "data-cy"]))
                    : _createCommentVNode("", true),
                  (
            element.vueType == 2 && element.propertyName != undefined && !element.isHidden
          )
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 3,
                        class: "background-blue",
                        rounded: "",
                        outlined: "",
                        ref_for: true,
                        ref: element.propertyName,
                        modelValue: element.value,
                        "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                        options: element.options,
                        label: element.title,
                        "option-value": "value",
                        "option-label": "text",
                        "emit-value": "",
                        "map-options": "",
                        disable: element.isReadOnly,
                        rules: element.isMandatory ? [_ctx.rules.required] : [],
                        "data-cy": element.propertyName + '-select'
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "disable", "rules", "data-cy"]))
                    : _createCommentVNode("", true),
                  (
            element.vueType == 7 &&
            element.propertyName != undefined &&
            element.value === '' &&
            !element.isHidden
          )
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 4,
                        class: "background-blue",
                        rounded: "",
                        outlined: "",
                        ref_for: true,
                        ref: element.propertyName,
                        modelValue: element.value,
                        "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                        multiple: "",
                        options: element.options,
                        "use-chips": "",
                        "stack-label": "",
                        label: element.title,
                        "lazy-rules": "",
                        "option-value": "value",
                        "option-label": "text",
                        "emit-value": "",
                        "map-options": "",
                        "input-debounce": "0",
                        disable: element.isReadOnly,
                        rules: element.isMandatory ? [_ctx.rules.required] : [],
                        "data-cy": element.propertyName + '-select'
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "disable", "rules", "data-cy"]))
                    : _createCommentVNode("", true),
                  (
            element.vueType == 7 &&
            element.propertyName != undefined &&
            element.value !== '' &&
            !element.isHidden
          )
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 5,
                        class: "background-blue",
                        rounded: "",
                        outlined: "",
                        ref_for: true,
                        ref: element.propertyName,
                        modelValue: element.value,
                        "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                        multiple: "",
                        options: element.options,
                        "use-chips": "",
                        "stack-label": "",
                        label: element.title,
                        "lazy-rules": "",
                        "option-value": "value",
                        "option-label": "text",
                        "emit-value": "",
                        "map-options": "",
                        disable: element.isReadOnly,
                        rules: element.isMandatory ? [_ctx.rules.required] : [],
                        "data-cy": element.propertyName + '-select'
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "disable", "rules", "data-cy"]))
                    : _createCommentVNode("", true),
                  (
            element.vueType == 11 &&
            element.propertyName != undefined &&
            !element.isHidden
          )
                    ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(element.title), 1))
                    : _createCommentVNode("", true),
                  (
            element.vueType == 11 &&
            element.propertyName != undefined &&
            !element.isHidden
          )
                    ? (_openBlock(), _createBlock(_component_q_option_group, {
                        key: 7,
                        class: "margin-top-minus-12",
                        options: element.options,
                        inline: "",
                        ref_for: true,
                        ref: element.propertyName,
                        modelValue: element.value,
                        "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                        disable: element.isReadOnly,
                        rules: element.isMandatory ? [_ctx.rules.required] : [],
                        "data-cy": element.propertyName + '-option'
                      }, null, 8, ["options", "modelValue", "onUpdate:modelValue", "disable", "rules", "data-cy"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_q_btn, {
              class: "default-btn",
              flat: "",
              label: _ctx.$t('button.filter'),
              icon: "filter_alt",
              disable: _ctx.disable,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter())),
              "data-cy": "filterFilterButton-button"
            }, null, 8, ["label", "disable"])
          ]),
          (_ctx.formElements.filter((t) => t.isDialog).length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_q_btn, {
                  flat: "",
                  class: "q-px-none",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = true)),
                  "data-cy": "filterOpenFilterDialog-button"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "mid-blue",
                          icon: "far fa-filter"
                        })
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("button.filters")), 1)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.formElements.filter((t) => t.isDialog).length > 0)
      ? (_openBlock(), _createBlock(_component_q_dialog, {
          key: 1,
          modelValue: _ctx.showDialog,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showDialog) = $event)),
          persistent: "",
          "transition-show": "scale",
          "transition-hide": "scale"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card, { class: "small-modal__container q-page q-page-container q-pl-none" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_form, {
                  onSubmit: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filter())),
                  class: "form-container height-100-perc width-100-perc"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.$t("filter.filterOptions")), 1),
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formElements.filter((t) => t.isDialog), (element) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "col-10 q-my-xs q-form",
                          key: element.propertyName
                        }, [
                          (
                element.vueType == 5 &&
                element.propertyName != undefined &&
                element.isReadOnly == false &&
                !element.isHidden
              )
                            ? (_openBlock(), _createBlock(_component_q_input, {
                                key: 0,
                                dense: "",
                                rounded: "",
                                outlined: "",
                                type: "text",
                                ref_for: true,
                                ref: element.propertyName,
                                modelValue: element.value,
                                "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                label: element.title,
                                "lazy-rules": "",
                                disable: element.isReadOnly,
                                rules: element.isMandatory ? [_ctx.rules.required] : [],
                                "data-cy": element.propertyName + '-input'
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disable", "rules", "data-cy"]))
                            : _createCommentVNode("", true),
                          (
                element.vueType == 6 &&
                element.propertyName != undefined &&
                element.isReadOnly == false &&
                !element.isHidden
              )
                            ? (_openBlock(), _createBlock(_component_q_input, {
                                key: 1,
                                dense: "",
                                rounded: "",
                                outlined: "",
                                type: "text",
                                ref_for: true,
                                ref: element.propertyName,
                                modelValue: element.value,
                                "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                label: element.title,
                                "lazy-rules": "",
                                class: "read-only-field",
                                disable: element.isReadOnly,
                                rules: element.isMandatory ? [_ctx.rules.required] : [],
                                "data-cy": element.propertyName + '-input'
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disable", "rules", "data-cy"]))
                            : _createCommentVNode("", true),
                          (
                element.vueType == 13 &&
                element.propertyName != undefined &&
                !element.isHidden
              )
                            ? (_openBlock(), _createBlock(_component_q_input, {
                                key: 2,
                                dense: "",
                                rounded: "",
                                outlined: "",
                                ref_for: true,
                                ref: element.propertyName,
                                modelValue: element.value,
                                "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                label: element.title,
                                disable: element.isReadOnly,
                                rules: element.isMandatory ? [_ctx.rules.required] : [],
                                "data-cy": element.propertyName + '-input'
                              }, {
                                append: _withCtx(() => [
                                  _createVNode(_component_q_icon, {
                                    name: "event",
                                    class: "cursor-pointer"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_popup_proxy, {
                                        cover: "",
                                        "transition-show": "scale",
                                        "transition-hide": "scale"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_date, {
                                            modelValue: element.value,
                                            "onUpdate:modelValue": ($event: any) => ((element.value) = $event),
                                            mask: "DD/MM/YYYY"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_12, [
                                                _withDirectives(_createVNode(_component_q_btn, {
                                                  label: "Close",
                                                  color: "primary",
                                                  flat: ""
                                                }, null, 512), [
                                                  [_directive_close_popup]
                                                ])
                                              ])
                                            ]),
                                            _: 2
                                          }, 1032, ["modelValue", "onUpdate:modelValue"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue", "label", "disable", "rules", "data-cy"]))
                            : _createCommentVNode("", true),
                          (
                element.vueType == 2 &&
                element.propertyName != undefined &&
                !element.isHidden
              )
                            ? (_openBlock(), _createBlock(_component_q_select, {
                                key: 3,
                                dense: "",
                                class: "background-blue",
                                rounded: "",
                                outlined: "",
                                ref_for: true,
                                ref: element.propertyName,
                                modelValue: element.value,
                                "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                options: element.options,
                                label: element.title,
                                "option-value": "value",
                                "option-label": "text",
                                "emit-value": "",
                                "map-options": "",
                                disable: element.isReadOnly,
                                rules: element.isMandatory ? [_ctx.rules.required] : [],
                                "data-cy": element.propertyName + '-select'
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "disable", "rules", "data-cy"]))
                            : _createCommentVNode("", true),
                          (
                element.vueType == 7 &&
                element.propertyName != undefined &&
                element.value === '' &&
                !element.isHidden
              )
                            ? (_openBlock(), _createBlock(_component_q_select, {
                                key: 4,
                                dense: "",
                                class: "background-blue",
                                rounded: "",
                                outlined: "",
                                ref_for: true,
                                ref: element.propertyName,
                                modelValue: element.value,
                                "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                multiple: "",
                                options: element.options,
                                "use-chips": "",
                                "stack-label": "",
                                label: element.title,
                                "lazy-rules": "",
                                "option-value": "value",
                                "option-label": "text",
                                "emit-value": "",
                                "map-options": "",
                                "input-debounce": "0",
                                disable: element.isReadOnly,
                                rules: element.isMandatory ? [_ctx.rules.required] : [],
                                "data-cy": element.propertyName + '-select'
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "disable", "rules", "data-cy"]))
                            : _createCommentVNode("", true),
                          (
                element.vueType == 7 &&
                element.propertyName != undefined &&
                element.value !== '' &&
                !element.isHidden
              )
                            ? (_openBlock(), _createBlock(_component_q_select, {
                                key: 5,
                                dense: "",
                                class: "background-blue",
                                rounded: "",
                                outlined: "",
                                ref_for: true,
                                ref: element.propertyName,
                                modelValue: element.value,
                                "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                multiple: "",
                                options: element.options,
                                "use-chips": "",
                                "stack-label": "",
                                label: element.title,
                                "lazy-rules": "",
                                "option-value": "value",
                                "option-label": "text",
                                "emit-value": "",
                                "map-options": "",
                                disable: element.isReadOnly,
                                rules: element.isMandatory ? [_ctx.rules.required] : [],
                                "data-cy": element.propertyName + '-select'
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label", "disable", "rules", "data-cy"]))
                            : _createCommentVNode("", true),
                          (
                element.vueType == 11 &&
                element.propertyName != undefined &&
                !element.isHidden
              )
                            ? (_openBlock(), _createElementBlock("label", _hoisted_13, _toDisplayString(element.title), 1))
                            : _createCommentVNode("", true),
                          (
                element.vueType == 11 &&
                element.propertyName != undefined &&
                !element.isHidden
              )
                            ? (_openBlock(), _createBlock(_component_q_option_group, {
                                key: 7,
                                dense: "",
                                class: "margin-top-minus-12",
                                options: element.options,
                                inline: "",
                                ref_for: true,
                                ref: element.propertyName,
                                modelValue: element.value,
                                "onUpdate:modelValue": [($event: any) => ((element.value) = $event), ($event: any) => (_ctx.enableApplyButton(element.propertyName))],
                                disable: element.isReadOnly,
                                rules: element.isMandatory ? [_ctx.rules.required] : [],
                                "data-cy": element.propertyName + '-option'
                              }, null, 8, ["options", "modelValue", "onUpdate:modelValue", "disable", "rules", "data-cy"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]),
                    _createVNode(_component_q_card_actions, { align: "right" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          dense: "",
                          flat: "",
                          color: "primary",
                          label: _ctx.$t('button.filterCancel'),
                          onClick: _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog = false), _ctx.clearFilter())),
                          "data-cy": "filterCloseFilterDialog-button"
                        }, null, 8, ["label"]),
                        _createVNode(_component_q_btn, {
                          dense: "",
                          flat: "",
                          color: "primary",
                          label: _ctx.$t('button.filterApply'),
                          type: "submit",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDialog = false)),
                          "data-cy": "filterApplyFilterDialog-button"
                        }, null, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}