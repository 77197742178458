<style lang="scss" src="./Header.scss"></style>
<script lang="ts" src="./Header.ts"></script>

<template>
  <q-header>
    <div class="profile-main-container">
      <div class="profile-brand">
        <img :src="brandImage" alt="Brand image" class="brand-image" />
      </div>
      <img
        src="../../assets/images/company-circle.png"
        class="profile-company-circle"
      />
      <div class="profile-container" @click="dialogToggle()">
        <div class="profile-avatar-container">
          <div class="avatar">
            <img :src="profileImage" class="avatar-image" />
            <span class="material-icons"> arrow_drop_down </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="dialog-container"
      v-if="profileDialog"
      @mouseleave="dialogToggle()"
    >
      <div class="dialog-bubble">
        <span class="material-icons"> arrow_drop_up </span>
        <div class="dialog-bubble-content">
          <div class="dialog-header">
            <h2>{{ $t("header.myProfile") }}</h2>
          </div>
          <div class="dialog-main">
            <div class="profile-avatar-container">
              <div class="avatar">
                <img :src="profileImage" class="avatar-image" />
              </div>
              <div class="avatar-info">
                {{ userName }}
                <sub> {{ userEmail }}</sub>
              </div>
            </div>
          </div>
          <div class="dialog-footer">
            <q-btn
              class="btn btn-dialog-footer"
              :label="$t('header.settings')"
              to="/settings/account/"
            />
            <q-btn
              class="btn btn-dialog-footer margin-left-190"
              :label="$t('header.signOut')"
              @click="logOut()"
            />
          </div>
        </div>
      </div>
    </div>
  </q-header>
</template>
