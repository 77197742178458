import { library } from '@fortawesome/fontawesome-svg-core'

//Solid
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck'
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons/faArrowDownToLine'
import { faCircleArrowDown } from '@fortawesome/pro-solid-svg-icons/faCircleArrowDown'
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons/faCircleArrowRight'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare'
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faServer } from '@fortawesome/pro-solid-svg-icons/faServer'
import { faChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine'
import { faArrowRightArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowRightArrowLeft'
import { faHandHoldingDollar } from '@fortawesome/pro-solid-svg-icons/faHandHoldingDollar'
import { faChartLineUp } from '@fortawesome/pro-solid-svg-icons/faChartLineUp'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding'

//light
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { faBullseye } from '@fortawesome/pro-light-svg-icons/faBullseye'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck'
import { faCircleDot } from '@fortawesome/pro-light-svg-icons/faCircleDot'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake'
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faClose } from '@fortawesome/pro-light-svg-icons/faClose'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faCircleUser } from '@fortawesome/pro-light-svg-icons/faCircleUser'
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign'
import { faFileCircleCheck } from '@fortawesome/pro-light-svg-icons/faFileCircleCheck'
import { faFileLines } from '@fortawesome/pro-light-svg-icons/faFileLines'
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser'
import { faTag as faTagLight } from '@fortawesome/pro-light-svg-icons/faTag'
import { faBadgeCheck as faBadgeCheckLight } from '@fortawesome/pro-light-svg-icons/faBadgeCheck'
import { faShareNodes } from '@fortawesome/pro-light-svg-icons/faShareNodes'
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons/faEllipsisVertical'
import { faBarcodeRead } from '@fortawesome/pro-light-svg-icons/faBarcodeRead'
import { faBan as faBanLight } from '@fortawesome/pro-light-svg-icons/faBan'
import { faDonate } from '@fortawesome/pro-light-svg-icons/faDonate'
import { faCircleDollarToSlot } from '@fortawesome/pro-light-svg-icons/faCircleDollarToSlot'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation'
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare'
import { faBasketShopping } from '@fortawesome/pro-light-svg-icons/faBasketShopping'
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faTh } from '@fortawesome/pro-light-svg-icons/faTh'
import { faServer as faServerLight } from '@fortawesome/pro-light-svg-icons/faServer'
import { faChartLine as faChartLineLight } from '@fortawesome/pro-light-svg-icons/faChartLine'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink'
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { faScrubber } from '@fortawesome/pro-light-svg-icons/faScrubber'

//regular
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload'
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faQuestion } from '@fortawesome/pro-regular-svg-icons/faQuestion'
import { faBadgeCheck as faBadgeCheckRegular } from '@fortawesome/pro-regular-svg-icons/faBadgeCheck'

export default function iconPack() {
    //Solid
    library.add(
        faBadgeCheck,
        faArrowDownToLine,
        faCircleArrowDown,
        faCircleArrowRight,
        faArrowUpRightFromSquare,
        faBan,
        faTag,
        faServer,
        faChartLine,
        faBadgeCheckRegular,
        faArrowRightArrowLeft,
        faHandHoldingDollar,
        faChartLineUp,
        faBuilding
    )
    //Light
    library.add(
        faAngleRight,
        faCircleQuestion,
        faGlobe,
        faBullseye,
        faChevronLeft,
        faClipboardCheck,
        faCircleDot,
        faLock,
        faHandshake,
        faPaperPlane,
        faClose,
        faTrash,
        faCircleUser,
        faCircle,
        faDollarSign,
        faFileCircleCheck,
        faFileLines,
        faUser,
        faTagLight,
        faBadgeCheckLight,
        faShareNodes,
        faEllipsisVertical,
        faBarcodeRead,
        faBanLight,
        faDonate,
        faCircleDollarToSlot,
        faCircleExclamation,
        faTriangleExclamation,
        faPenToSquare,
        faBasketShopping,
        faUsers,
        faTh,
        faServerLight,
        faChartLineLight,
        faCog,
        faLink,
        faHome,
        faLayerGroup,
        faScrubber
    )
    //Regular
    library.add(
        faUpload,
        faFilter,
        faChevronRight,
        faQuestion
    )
}